<ngx-scanner-qrcode #action="scanner" (event)="onEventQrCode($event)"></ngx-scanner-qrcode>

<div class="control-overlay">
  <div *ngIf="activity" class="activity">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>

  <!-- ################# AREA 1: TOP #################-->
  <div class="row-1 form-field-density-2">
    <mat-form-field appearance="outline" color="accent" class="width-100 camera-selection" subscriptSizing="dynamic">
      <mat-select [(ngModel)]="selectedDevice" class="color-accent-400">
        <mat-option *ngFor="let device of devices" [value]="device">{{
          device.label
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button color="accent" class="ml-tiny" (click)="debugMode = !debugMode">
      <mat-icon fontSet="material-symbols-outlined">bug_report</mat-icon>
    </button>
  </div>

  <!-- ################# AREA 2: MIDDLE ################# -->
  <div class="row-2-debug" *ngIf="debugMode">
    <span *ngFor="let debugEvent of debugEvents">
      {{ debugEvent }}
    </span>
  </div>

  <div class="row-2" *ngIf="!debugMode">
    <div *ngFor="let relevantAsset of relevantAssets | keyvalue" class="flex-row align-items-center gap-normal">
      <!-- <span
        class="text cursor-pointer"
        (click)="
          manualKeyboardInput = relevantAsset.key;
          manualKeyboardInputExpansionPanel.open()
        "
      >
        {{ relevantAsset.key }}
      </span> -->
      <span class="text">
        {{ relevantAsset.key }}
      </span>
      <div [ngClass]="relevantAsset.value.scanned ? 'mark' : ''">
        <mat-icon *ngIf="
            relevantAsset.value.booked || relevantAsset.value.scanned;
            else noBookedOrScannedElement
          ">
          check
        </mat-icon>
        <ng-template #noBookedOrScannedElement>
          <mat-icon>space_bar</mat-icon>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- ################# AREA 3: BOTTOM ACTION BUTTONS ################# -->
  <div class="row-3">
    <div class="keyboard">
      <button mat-icon-button color="accent" (click)="onClickKeyboard(false)">
        <mat-icon fontSet="material-symbols-outlined">keyboard</mat-icon>
      </button>
    </div>
  </div>

  <!-- ################# AREA 4: BOTTOM ################# -->
  <div class="row-4">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{
          planStepActionHelper.getActionName(
          inputData?.planStepAction,
          "uppercase"
          )
          }}
        </mat-panel-title>
        <mat-panel-description>
          <span *ngIf="inputData?.planStepAction?.date">
            {{
            $any(inputData?.planStepAction?.date)
            | date : localeService.datetimePipeString(locale)
            }}
          </span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="flex-column font-size-12 line-height-14">
        <div class="flex-row gap-tiny overflow-x-hidden">
          <span class="font-weight-500">PLAN:</span>
          <span class="ellipsis">
            {{ inputData?.plan?.name }}
          </span>
        </div>

        <ng-container *ngIf="inputData?.plan?.description">
          <span class="mt-small font-weight-500">PLAN INFO:</span>
          <span>{{ inputData?.plan?.description }}</span>
        </ng-container>

        <div class="mt-small flex-row gap-tiny overflow-x-hidden">
          <span class="font-weight-500">STEP:</span>
          <span class="ellipsis">
            {{ inputData?.planStep?.name }}
          </span>
        </div>

        <ng-container *ngIf="inputData?.planStep?.description">
          <span class="mt-small font-weight-500">STEP INFO:</span>
          <span>{{ inputData?.planStep?.description }}</span>
        </ng-container>

        <div *ngIf="
            inputData?.planStepAction?.transitLocationId as transitLocationId
          " class="mt-small flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500">HANDOVER LOCATION:</span>
          <lib-location [locationId]="transitLocationId"></lib-location>
        </div>

        <div class="mt-small flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500">FROM:</span>
          <app-action-details fromOrTo="from" [action]="inputData?.planStepAction" class="ellipsis">
          </app-action-details>
        </div>

        <div class="mt-small flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500">TO:</span>
          <app-action-details fromOrTo="to" [action]="inputData?.planStepAction" class="ellipsis">
          </app-action-details>
        </div>
      </div>
    </mat-expansion-panel>

    <div class="grid-2">
      <button mat-stroked-button (click)="onClickClose()" [disabled]="activity">
        CANCEL
      </button>
      <button mat-flat-button color="accent" (click)="onClickBook()"
        [disabled]="activity || scannedAssetIds.length === 0">
        <span>BOOK ({{ scannedAssetIds.length }})</span>
      </button>
    </div>
  </div>
</div>