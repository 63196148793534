import { Component, Inject, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { PhoneToastService } from '../../services/phone-toast.service';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { LOCAL_STORAGE } from '../../common/localStorage';
import { Location } from '@angular/common';
import {
  NgScanKeyboardDialogComponent,
  NgScanKeyboardDialogResult,
} from '../../component-helpers/ng-scan/ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component';
import {
  ConfirmInventoryAssetMutationArgs,
  ConfirmInventoryAssetMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/inventoryAsset';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import { PhoneToastDialogType } from '../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;
  devices: ScannerQRCodeDevice[] = [];
  cameraIsOn = true;
  activity = false;
  successfulInventoryScans = new Map<string, InventoryOutput[]>();
  failedInventoryScans = new Set<string>();

  get selectedDevice() {
    return this.#selectedDevice;
  }
  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this.#selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);

      this.scanner.isBeep = false;
      this.scanner.playDevice(value.deviceId);
    }
  }

  #selectedDevice: ScannerQRCodeDevice | undefined;
  #lastSuccessfulAssetIdScan: string | undefined;

  constructor(
    private apollo: Apollo,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private matDialog: MatDialog,
    private assetService: AssetService,
    private toastService: PhoneToastService,
    private location: Location
  ) { }

  ngAfterViewInit(): void {
    this.#startCamera();
  }

  ngOnDestroy(): void {
    this.scanner.stop();
  }

  toggleCamera() {
    if (this.cameraIsOn) {
      this.scanner.stop();
    } else {
      this.#startCamera();
    }

    this.cameraIsOn = !this.cameraIsOn;
    (document.activeElement as HTMLElement)?.blur();
  }

  onClickClose() {
    this.location.back();
  }

  async onEventCode(events: ScannerQRCodeResult[]) {
    for (const id of events.map((x) => x.value)) {
      if (id === this.#lastSuccessfulAssetIdScan) {
        continue; // Avoid multiple reloads due to continues scanning.
      }

      this.#handleAssetId(id);
    }
  }

  onClickKeyboard(multiScan: boolean) {
    const dialog = this.matDialog.open(NgScanKeyboardDialogComponent, { data: multiScan });

    dialog.afterClosed().subscribe((result: NgScanKeyboardDialogResult) => {
      if (!result?.assetId) {
        return;
      }

      (document.activeElement as HTMLElement)?.blur();

      this.#handleAssetId(result.assetId);
      if (result.multiScan)
        this.onClickKeyboard(result.multiScan);
    });
  }


  #startCamera() {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  async #handleAssetId(assetId: string) {
    if (
      this.activity ||
      this.successfulInventoryScans.has(assetId) ||
      this.failedInventoryScans.has(assetId)
    ) {
      return;
    }

    this.activity = true;
    try {
      const inventories = await this.#confirmInventoryAsset(assetId);
      if (inventories.length > 0) {
        this.successfulInventoryScans.set(assetId, inventories);
      } else {
        this.failedInventoryScans.add(assetId);
      }
    } catch (error) {
      this.toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }

  async #confirmInventoryAsset(assetId: string): Promise<InventoryOutput[]> {
    const variables: ConfirmInventoryAssetMutationArgs = {
      assetId,
    };

    const result = await firstValueFrom(
      this.apollo.mutate<ConfirmInventoryAssetMutationRoot>({
        mutation: gql`
          ${FULL_FRAGMENT_INVENTORY}
          mutation ConfirmInventoryAsset($assetId: String!) {
            confirmInventoryAsset(assetId: $assetId) {
              ...FullFragmentInventory
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );
    return result.data?.confirmInventoryAsset ?? [];
  }
}
