export type UIDefectState = {
  id: number;
  name: string;
  tag: string;
};

export const uiDefectStates = new Map<number, UIDefectState>([
  [4, { id: 4, name: 'FROZEN', tag: 'frozen' }],
  [3, { id: 3, name: 'DEFECT 100', tag: 'nonfunctional' }],
  [2, { id: 2, name: 'DEFECT', tag: 'partially functional' }],
  [1, { id: 1, name: 'OK', tag: 'fully functional' }],
]);
