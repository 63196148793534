<mat-dialog-content>
  <div class="flex-column gap-normal">
    <div>
      <!-- Checkbox for "multi-scan"-->
      <mat-checkbox [(ngModel)]="multiScan">
        Multi-Input
      </mat-checkbox>
    </div>

    <div class="form-field-density-2">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>Asset ID</mat-label>
        <input matInput type="text" [autocomplete]="uuid1" [(ngModel)]="assetId" (keydown.enter)="onClickApply(true)" />
      </mat-form-field>
    </div>

    <button mat-flat-button (click)="onClickApply(false)" [disabled]="!assetId" color="primary">
      ADD
    </button>

    <div style="height: 4px"></div>
  </div>
</mat-dialog-content>