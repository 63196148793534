import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { v4 } from 'uuid';

export type NgScanKeyboardDialogResult = {
  assetId: string;
  multiScan: boolean;
} | undefined;

@Component({
  selector: 'app-ng-scan-keyboard-dialog',
  templateUrl: './ng-scan-keyboard-dialog.component.html',
  styleUrls: ['./ng-scan-keyboard-dialog.component.scss'],
})
export class NgScanKeyboardDialogComponent {
  assetId: string | undefined;
  multiScan: boolean | undefined;
  uuid1 = v4();

  constructor(
    private _dialogRef: MatDialogRef<NgScanKeyboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean | undefined,
  ) {
    this.multiScan = data;
  }

  onClickApply(autoClose: boolean) {
    if (!this.assetId) {
      return;
    }

    this._dialogRef.close({ assetId: this.assetId, multiScan: this.multiScan });
  }
}
